
import passwordValidator from 'password-validator';

const DEBOUNCE_TIMEOUT    = 300;
const MESSAGES_LANGUAGE = {
  fr: {
    min: '8 caractères minimum',
    uppercase: '1 majuscule',
    lowercase: '1 minuscule',
    digits: '1 chiffre',
    usingPlugin: '1 caractère spécial (* ! ? # ^ $ % @)'
  },
  en: {
    min: '8 characters minimum',
    uppercase: '1 uppercase',
    lowercase: '1 lowercase',
    digits: '1 digit',
    usingPlugin: '1 special character (* ! ? # ^ $ % @)'
  }
};
const PASSWORD_MIN_LENGTH = 8;
const schema = new passwordValidator();
const TOGGLE_SUBMIT_TIMEOUT = 100;


// Helper function to create icon element
const createIcon = (className) => {
  const icon = document.createElement("i");
  icon.className = className;
  return icon;
};

// Helper function for debuouncing
const debounce = (func, timeout = DEBOUNCE_TIMEOUT) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args) }, timeout);
  }
};

// Helper function to check if a string contains a special char
const hasSpecialCharacter = (str) => {
  const regexPattern = /[-#$.%&@!+=\\*?]/;
  return regexPattern.test(str);
};

const renderInitialMessages = (data, parentElement) => {
  for (const key in data) {
    const li = document.createElement('li');
    const errorText = data[key];
    const icon = createIcon('fas fa-times mr-2 text-danger');

    li.setAttribute('id', key);
    li.innerHTML = `<span>${errorText}</span>`;
    li.querySelector('span').insertAdjacentElement('afterbegin', icon);
    parentElement.appendChild(li);
  }
};

// Define password validation schema
schema
  .is().min(PASSWORD_MIN_LENGTH, "8 caractères minimum")                         // Minimum length 8
  .usingPlugin(hasSpecialCharacter, 'Must have one special character')           // Must have a special character
  .has().uppercase(1, "1 majuscule")                                             // Must have uppercase letters
  .has().lowercase(1, "1 minuscule")                                             // Must have lowercase letters
  .has().digits(1, "1 chiffre")                                                  // Must have at least 1 digits
// .has().not().spaces()                                                         // Should not have spaces
// .is().not().oneOf(["Passw0rd", "Password123", "password", "00000000"])        // Blacklist these values

const toggleSubmitButton = (state, index = 0) => {
  // console.log(`Toggling button: ${index} with state: ${state}`)
  let submitButtons;
  setTimeout(function () { // Uses timeout for weird behavior when page renders!!!
  submitButtons = document.querySelectorAll('[type="submit"]');
  if (submitButtons[index]) submitButtons[index].removeAttribute('disabled');
  if (submitButtons.length < 2) index = 0;
  if (state === 'enable') {
    submitButtons[index].removeAttribute('disabled');
    submitButtons[index].style.pointerEvents = 'auto';
  } else if (state === 'disable') {
      submitButtons[index].disabled = true;
      submitButtons[index].style.pointerEvents = 'none';
  }
  }, TOGGLE_SUBMIT_TIMEOUT);
};

// Helper function to create a ul container for password messages
const createUl = (className) => {
  const ul = document.createElement('ul');
  ul.classList.add(className);
  return ul;
};

// Helper function to style the messages' container
const styleListOfMessages = (ul, padding) => {
  ul.style.listStyleType = `none`;
  ul.style.padding = padding;
};

// Helper function to update the list of message with a custom message
const updateMessagesList = (messagesObject) => {
  let extraMessagesObject = messagesObject; // hack to add to second input with validation
  extraMessagesObject["same"] = currentLocale == 'fr' ? `Les 2 mots de passe doivent être identiques` : `The 2 passwords must be identical`;
  return extraMessagesObject
};

// Code to execute when page is ready:
const callbackLogic = () => {
  const input = document.querySelectorAll('input[type="password"]');
  let messagesObject = MESSAGES_LANGUAGE[currentLocale];
  let isInDistributorSpace;

  if (input[2]) isInDistributorSpace = input[2].classList.contains('space-password');

  let parentOfInput;
  let parentOfInputSecond;
  let parentOfInputConfirmation;
  let ul  = createUl('password-checker_list');
  let ul2 = createUl('password-checker_list');
  let ul3 = createUl('password-checker_list');

  if (input.length === 2) { // Used in for new password after forgot password req!!
    styleListOfMessages(ul, window.getComputedStyle(input[1]).getPropertyValue('padding'));
    renderInitialMessages(updateMessagesList(messagesObject), ul);
    parentOfInputSecond = input[0].parentElement;
    parentOfInputSecond.append(ul);

  } else if(input.length > 2) {
    if(isInDistributorSpace) {
      // Handle password in distributor space
      styleListOfMessages(ul, window.getComputedStyle(input[1]).getPropertyValue('padding'));
      renderInitialMessages(updateMessagesList(messagesObject), ul);
      parentOfInputSecond = input[0].parentElement;
      parentOfInputSecond.append(ul);
    }else {
      // Append messages to the ul container
      styleListOfMessages(ul, window.getComputedStyle(input[0]).getPropertyValue('padding'));
      styleListOfMessages(ul2, window.getComputedStyle(input[1]).getPropertyValue('padding'));
      renderInitialMessages(messagesObject, ul);
      renderInitialMessages(updateMessagesList(messagesObject), ul2);
      parentOfInput = input[0].parentElement;
      parentOfInputSecond = input[1].parentElement;
      parentOfInputSecond.append(ul2);
      parentOfInput.setAttribute('id', 'first-password');
      parentOfInput.append(ul);
    }
  }

  toggleSubmitButton('disable');

  //NOTE: Commented to fix signUP button issue
  // if (input.length > 1) toggleSubmitButton('disable', 1);

  if (input.length > 2) {
    if(isInDistributorSpace) {
      toggleSubmitButton('enable')
      input[0].addEventListener(
        "input",
        debounce((event) => {
          let value = event.target.value;
          let res = schema.validate(value, { details: true });
          let msgsToCheck = []
          let msgsToCross = []

          for (const key in messagesObject) {
            if (key !== 'same') {
              if (!res.some(obj => obj.validation === key)) {
                let validMsgElem = ul.querySelector(`#${key}`)
                if (validMsgElem) msgsToCheck.push(validMsgElem)
              } else {
                let validMsgElem = ul.querySelector(`#${key}`)
                if (validMsgElem) msgsToCross.push(validMsgElem)
              }
            }
          }

          msgsToCross.forEach(msgElm => {
            let crossIcon = createIcon('fas fa-times mr-2 text-danger')
            let span = msgElm.querySelector('span')
            let icon = span.querySelector('i')
            span.removeChild(icon)
            span.insertAdjacentElement('afterbegin', crossIcon)
          });
          msgsToCheck.forEach(msgElm => {
            let checkIcon = createIcon('fas fa-check mr-2 text-success')
            let span = msgElm.querySelector('span')
            let icon = span.querySelector('i')
            span.removeChild(icon)
            span.insertAdjacentElement('afterbegin', checkIcon)
          });
  
          if (schema.validate(value)) {
            if (input[1]) {
              input[1].addEventListener(
                "input", () => {
                  if (input[1].value === input[0].value) {
                    let validMsgElem = ul.lastElementChild
                    if (validMsgElem) msgsToCheck.push(validMsgElem);
                    msgsToCheck.forEach(msgElm => {
                      let checkIcon = createIcon('fas fa-check mr-2 text-success')
                      let span = msgElm.querySelector('span')
                      let icon = span.querySelector('i')
                      span.removeChild(icon)
                      span.insertAdjacentElement('afterbegin', checkIcon)
                    });
                    return toggleSubmitButton('enable', 1)
                  } else {
                    let validMsgElem = ul.lastElementChild;
                    if (validMsgElem) msgsToCross.push(validMsgElem);
                    msgsToCross.forEach(msgElm => {
                      let crossIcon = createIcon('fas fa-times mr-2 text-danger');
                      let span = msgElm.querySelector('span');
                      let icon = span.querySelector('i');
                      span.removeChild(icon);
                      span.insertAdjacentElement('afterbegin', crossIcon);
                    });
                    // redisable submit
                    toggleSubmitButton('enable', 1)
                  }
                })
            } else {
              toggleSubmitButton('enable', 1)
            }
          } else {
            toggleSubmitButton('enable', 1)
          }
        })
      );
    }else {
    //first password (without confirmation)
      input[0].addEventListener(
        "input",
        debounce((event) => {
          let value = event.target.value;
          let res = schema.validate(value, { details: true });
          let msgsToCheck = []
          let msgsToCross = []

          for (const key in messagesObject) {
            if (!res.some(obj => obj.validation === key)) {
              let validMsgElem = ul.querySelector(`#${key}`)
              if (validMsgElem) msgsToCheck.push(validMsgElem);
            } else {
              let validMsgElem = ul.querySelector(`#${key}`)
              if (validMsgElem) msgsToCross.push(validMsgElem);
            }
          }

          msgsToCross.forEach(msgElm => {
            let crossIcon = createIcon('fas fa-times mr-2 text-danger')
            let span = msgElm.querySelector('span')
            let icon = span.querySelector('i')
            span.removeChild(icon)
            span.insertAdjacentElement('afterbegin', crossIcon)
          });
          msgsToCheck.forEach(msgElm => {
            let checkIcon = createIcon('fas fa-check mr-2 text-success')
            let span = msgElm.querySelector('span')
            let icon = span.querySelector('i')
            span.removeChild(icon)
            span.insertAdjacentElement('afterbegin', checkIcon)
          });

          if (schema.validate(value)) {
            toggleSubmitButton('enable')
          } else {
            toggleSubmitButton('disable')
          }
        })
      );

    //second password (+ confirmation)
      input[1].addEventListener(
        "input",
        debounce((event) => {
          let value = event.target.value;
          let res = schema.validate(value, { details: true });
          let msgsToCheck = []
          let msgsToCross = []
          for (const key in messagesObject) {
            if (key !== 'same') {
              if (!res.some(obj => obj.validation === key)) {
                let validMsgElem = ul2.querySelector(`#${key}`)
                if (validMsgElem) msgsToCheck.push(validMsgElem);
              } else {
                let validMsgElem = ul2.querySelector(`#${key}`)
                if (validMsgElem) msgsToCross.push(validMsgElem);
              }
            }
          }

          msgsToCross.forEach(msgElm => {
            let crossIcon = createIcon('fas fa-times mr-2 text-danger')
            let span = msgElm.querySelector('span')
            let icon = span.querySelector('i')
            span.removeChild(icon)
            span.insertAdjacentElement('afterbegin', crossIcon)
          });
          msgsToCheck.forEach(msgElm => {
            let checkIcon = createIcon('fas fa-check mr-2 text-success')
            let span = msgElm.querySelector('span')
            let icon = span.querySelector('i')
            span.removeChild(icon)
            span.insertAdjacentElement('afterbegin', checkIcon)
          });

          if (schema.validate(value)) {
            if (input[2]) {
              input[2].addEventListener(
                "input", () => {
                  if (input[2].value === input[1].value) {
                    let validMsgElem = ul2.lastElementChild
                    if (validMsgElem) msgsToCheck.push(validMsgElem);
                    msgsToCheck.forEach(msgElm => {
                      let checkIcon = createIcon('fas fa-check mr-2 text-success')
                      let span = msgElm.querySelector('span')
                      let icon = span.querySelector('i')
                      span.removeChild(icon)
                      span.insertAdjacentElement('afterbegin', checkIcon)
                    });
                    return toggleSubmitButton('enable', 1)
                  } else {
                    let validMsgElem = ul2.lastElementChild
                    if (validMsgElem) msgsToCross.push(validMsgElem);
                    msgsToCross.forEach(msgElm => {
                      let crossIcon = createIcon('fas fa-times mr-2 text-danger')
                      let span = msgElm.querySelector('span')
                      let icon = span.querySelector('i')
                      span.removeChild(icon)
                      span.insertAdjacentElement('afterbegin', crossIcon)
                    });
                    // redisable submit
                    toggleSubmitButton('disable', 1)
                  }
                })
            } else {
              toggleSubmitButton('enable', 1)
            }
          } else {
            //NOTE: Commented to stop the distributor's sign_up button from being disabled: this change affects the investor's button as they share the same button (investor form has a validator on submit)
            //NOTE: This line must be commented ! Untill refactoring of the login & signUp view !
            // toggleSubmitButton('disable', 1)
          }
        })
      );
    }

  } else if (input.length === 2) {
    //second password (+ confirmation)
    input[0].addEventListener(
      "input",
      debounce((event) => {
        let value = event.target.value;
        let res = schema.validate(value, { details: true });
        let msgsToCheck = []
        let msgsToCross = []
        for (const key in messagesObject) {
          if (key !== 'same') {
            if (!res.some(obj => obj.validation === key)) {
              let validMsgElem = ul.querySelector(`#${key}`)
              if (validMsgElem) msgsToCheck.push(validMsgElem)
            } else {
              let validMsgElem = ul.querySelector(`#${key}`)
              if (validMsgElem) msgsToCross.push(validMsgElem)
            }
          }
        }

        msgsToCross.forEach(msgElm => {
          let crossIcon = createIcon('fas fa-times mr-2 text-danger')
          let span = msgElm.querySelector('span')
          let icon = span.querySelector('i')
          span.removeChild(icon)
          span.insertAdjacentElement('afterbegin', crossIcon)
        });
        msgsToCheck.forEach(msgElm => {
          let checkIcon = createIcon('fas fa-check mr-2 text-success')
          let span = msgElm.querySelector('span')
          let icon = span.querySelector('i')
          span.removeChild(icon)
          span.insertAdjacentElement('afterbegin', checkIcon)
        });

        if (schema.validate(value)) {
          if (input[1]) {
            input[1].addEventListener(
              "input", () => {
                if (input[1].value === input[0].value) {
                  let validMsgElem = ul.lastElementChild
                  if (validMsgElem) msgsToCheck.push(validMsgElem);
                  msgsToCheck.forEach(msgElm => {
                    let checkIcon = createIcon('fas fa-check mr-2 text-success')
                    let span = msgElm.querySelector('span')
                    let icon = span.querySelector('i')
                    span.removeChild(icon)
                    span.insertAdjacentElement('afterbegin', checkIcon)
                  });
                  return toggleSubmitButton('enable', 0)
                } else {
                  let validMsgElem = ul.lastElementChild
                  if (validMsgElem) msgsToCross.push(validMsgElem);
                  msgsToCross.forEach(msgElm => {
                    let crossIcon = createIcon('fas fa-times mr-2 text-danger')
                    let span = msgElm.querySelector('span')
                    let icon = span.querySelector('i')
                    span.removeChild(icon)
                    span.insertAdjacentElement('afterbegin', crossIcon)
                  });
                  // redisable submit
                  toggleSubmitButton('disable', 0)
                }
              })
          } else {
            toggleSubmitButton('enable', 1)
          }
        } else {
          toggleSubmitButton('disable', 1)
        }
      })
    );
  } else {
    input[0].addEventListener(
      "input",
      debounce((event) => {
        let value = event.target.value;
        let res = schema.validate(value, { details: true });
        let msgsToCheck = []
        let msgsToCross = []
        for (const key in messagesObject) {
          if (!res.some(obj => obj.validation === key)) {
            let validMsgElem = ul.querySelector(`#${key}`);
            if (validMsgElem) msgsToCheck.push(validMsgElem);
          } else {
            let validMsgElem = ul.querySelector(`#${key}`);
            if (validMsgElem) msgsToCross.push(validMsgElem);
          }
        }

        msgsToCross.forEach(msgElm => {
          let crossIcon = createIcon('fas fa-times mr-2 text-danger')
          let span = msgElm.querySelector('span')
          let icon = span.querySelector('i')
          span.removeChild(icon)
          span.insertAdjacentElement('afterbegin', crossIcon)
        });

        msgsToCheck.forEach(msgElm => {
          let checkIcon = createIcon('fas fa-check mr-2 text-success')
          let span = msgElm.querySelector('span')
          let icon = span.querySelector('i')
          span.removeChild(icon)
          span.insertAdjacentElement('afterbegin', checkIcon)
        });

        if (schema.validate(value)) {
          toggleSubmitButton('enable')
        } else {
          toggleSubmitButton('disable')
        }
      })
    );
  }
}

const initializeUI = () => {
  const submitButtons = document.querySelectorAll('[type="submit"]')
  submitButtons.forEach((button) => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          if (button.classList.contains('distributor_sign_up')) {
            toggleSubmitButton('enable', 1)
          } else {
            toggleSubmitButton('disable', 1)
          }
        }
      })
    })
    observer.observe(button, {attributes: true})
  })
  callbackLogic()
};

// Page is Ready
document.addEventListener("DOMContentLoaded", initializeUI)
